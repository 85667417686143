<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="4">
          <BuildingDropdown :server-params="serverParams"
                            :on-select="onChange" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { openClose } from "@/shared/options";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    props: {
      serverParams: Object,
    },
    components: {
      BuildingDropdown,
    },
    data: () => ({
      addFilter: false,
      options: {
        openClose,
      },
    }),
    methods: {
      onChange() {
        this.serverParams.pageNumber = 1;
        this.$emit("onChange", this.serverParams);
      },
    },
  };
</script>
